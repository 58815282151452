.RefundPolicy {
    padding: 10vmin;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5vmin;
    line-height: 2.5vmin;
    text-align: justify;
}