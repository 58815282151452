

.Header {
    background-color: #fff;
    align-items: center;
    padding-top: 12px;
    width: 100%;
    padding-bottom: 12px;
    display: flex;
    z-index: 100;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ nav { width: 90%; justify-content: space-between; }}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ nav { width: 90%; justify-content: space-between; }}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ nav { max-width: 90%; justify-content: space-between; }}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ nav { max-width: 90%; justify-content: space-between; }}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ nav { width: 1200px; max-width: 90%; justify-content: space-between; }}
@media (min-width:1281px) { /* hi-res laptops and desktops */ nav { width: 1200px; max-width: 90%; justify-content: space-between; }}
nav {
    display: flex;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
}

.MenuAndLogo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.Logo {
    height: 30px;
}

.CompanyName {
    font-family: PoppinsMedium;
    margin-left: 10px;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ .Pages { display: none; } .RightMenuBtn { display: block; }}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ .Pages { display: none; } .RightMenuBtn { display: block; }}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ .Pages { display: none; } .RightMenuBtn { display: block; }}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ .Pages { display: flex; } .RightMenuBtn { display: none; }}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ .Pages { display: flex; } .RightMenuBtn { display: none; }}
@media (min-width:1281px) { /* hi-res laptops and desktops */ .Pages { display: flex; } .RightMenuBtn { display: none; }}
.Pages {
    align-items: center;
}



/* ==================================================== */
/* ============== Dropdowns =========================== */
/* ==================================================== */
.RequestMark {
    margin-right: 10px;
}

.DropdownMark {
    margin-left: 10px;
}



/* The dropdown container */
.IndustriesDropdown {
    float: left;
    overflow: hidden;
}
  
/* Dropdown button */
.IndustriesDropdown .IndustriesDropdownBtn {
    font-size: 16px;
    border: none;
    outline: none;
    align-items: center;
    display: flex;
    color: black;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.IndustriesDropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    z-index: 100;
}
  
/* Links inside the dropdown */
.IndustriesDropdownContent a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.IndustriesDropdownContent a:hover {
    background-color: #f3f3f3;
}

/* Show the dropdown menu on hover */
.IndustriesDropdown:hover .IndustriesDropdownContent {
    display: block;
}



/* The dropdown container */
.ServicesDropdown {
    float: left;
    overflow: hidden;
}
  
/* Dropdown button */
.ServicesDropdown .ServicesDropdownBtn {
    font-size: 16px;
    border: none;
    outline: none;
    align-items: center;
    display: flex;
    color: black;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.ServicesDropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    z-index: 100;
}
  
/* Links inside the dropdown */
.ServicesDropdownContent a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.ServicesDropdownContent a:hover {
    background-color: #f3f3f3;
}

/* Show the dropdown menu on hover */
.ServicesDropdown:hover .ServicesDropdownContent {
    display: block;
}


/* The dropdown container */
.ProductsDropdown {
    float: left;
    overflow: hidden;
}
  
/* Dropdown button */
.ProductsDropdown .ProductsDropdownBtn {
    font-size: 16px;
    border: none;
    outline: none;
    align-items: center;
    display: flex;
    color: black;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.ProductsDropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    z-index: 100;
}
  
/* Links inside the dropdown */
.ProductsDropdownContent a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.ProductsDropdownContent a:hover {
    background-color: #f3f3f3;
}

/* Show the dropdown menu on hover */
.ProductsDropdown:hover .ProductsDropdownContent {
    display: block;
}



/* The dropdown container */
.CompanyDropdown {
    float: left;
    overflow: hidden;
}
  
/* Dropdown button */
.CompanyDropdown .CompanyDropdownBtn {
    font-size: 16px;
    border: none;
    outline: none;
    align-items: center;
    display: flex;
    color: black;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.CompanyDropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    z-index: 100;
}
  
/* Links inside the dropdown */
.CompanyDropdownContent a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.CompanyDropdownContent a:hover {
    background-color: #f3f3f3;
}

/* Show the dropdown menu on hover */
.CompanyDropdown:hover .CompanyDropdownContent {
    display: block;
}