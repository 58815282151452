/* Delivering */

.DeliverInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 100vh;
    width: 67%;
    margin: auto;
}

.DeliverTitle {
    width: 60%;
    text-align: start;
    font-size: 8vmin;
    margin-top: 10vmin;
    margin-bottom: 10vmin;
    color: #000;
    font-family: PoppinsMedium;
}

.DeliverySteps {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Stepper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20vmin;
}

.Step {
    display: flex;
    margin-top: 50px;
}

.StepInfo {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}

.StepName {
    font-size: 22px;
    color: #000;
    font-family: PoppinsMedium;
    max-width: 380px;
}

.StepDesc {
    font-size: 14px;
    margin-top: 10px;
    color: #000;
    font-family: PoppinsLight;
    max-width: 380px;
}