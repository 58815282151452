/* Banner */

.Banner {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    margin: auto;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ .Banner video { width: 200vw; height: 100%; } }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ .Banner video { width: 200vw; height: 100%; } }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */  .Banner video { width: 200vw; height: 100%; }}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ .Banner video { width: 150vw; height: 100vh; } }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ .Banner video { width: 130vw; height: 100vh; } }
@media (min-width:1281px) { /* hi-res laptops and desktops */ .Banner video { width: 100%; height: 100%; }  }
.Banner video {
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -100;
    filter: brightness(50%);
}

.BannerTitle {
    position: absolute;
    top: 30vmin;
    left: 5%;
    z-index: 99;
    font-size: 4vmin;
    font-family: PoppinsBold;
    color: #fff;
}

.BannerSubtitle {
    position: absolute;
    top: 50vmin;
    left: 5%;
    width: 80vmin;
    z-index: 99;
    font-size: 2.5vmin;
    font-family: PoppinsMedium;
    color: #fff;
}

.BannerWidget {
    position: absolute;
    top: 75vmin;
    left: 5%;
    z-index: 99;
}

#SoftwareBannerBtn {
    position: absolute;
    top: 80vmin;
    left: 5%;
    width: max-content;
    padding: 10px 40px;
    z-index: 99;
    font-size: 14pt;
    font-family: PoppinsMedium;
    color: #fff;
    border: #000 1px solid;
    background: #000;
}

/* AboutSoftware */

.AboutSoftware {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    margin: auto;
    padding: 40px 0px;
}

.AboutTitle {
    width: 70%;
    text-align: start;
    font-size: 8vmin;
    margin-top: 10vmin;
    margin-bottom: 1vmin;
    color: #000;
    font-family: PoppinsMedium;
}

.AboutSubtitle {
    width: 70%;
    text-align: start;
    font-size: 18px;
    margin-bottom: 10vmin;
    color: #6e6e6e;
    font-style: italic;
    font-family: PoppinsMedium;
}

/* OurGoal */

.OurGoal {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    margin: auto;
    padding: 40px 0px;
}

.OurGoalTitle {
    width: 70%;
    text-align: start;
    font-size: 8vmin;
    margin-top: 10vmin;
    margin-bottom: 1vmin;
    color: #000;
    font-family: PoppinsMedium;
}

.GoalText {
    width: 70%;
    text-align: start;
    font-size: 18px;
    margin-bottom: 10vmin;
    color: #6e6e6e;
    font-style: italic;
    font-family: PoppinsMedium;
    background: linear-gradient(90deg, #F2F2F2 0%, #FFFFFF 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px 20px;
    margin-top: 10vmin;
}

.GoalTextTitle {
    font-size: 24px;
    margin-bottom: 10vmin;
    color: #000;
    font-family: PoppinsMedium;
}

.GoalTextContent {
    width: 80%;
    text-align: start;
    font-size: 18px;
    margin-bottom: 10vmin;
    color: #6e6e6e;
    font-style: italic;
    font-family: PoppinsMedium;
}

.GoalTextContentTitle {
    font-size: 24px;
    margin-bottom: 10vmin;
    color: #000;
    font-family: PoppinsMedium;
}

.GoalTextContentText {
    font-size: 18px;
    margin-bottom: 10vmin;
    color: #6e6e6e;
    font-style: italic;
    font-family: PoppinsMedium;
}

/* OurServices */