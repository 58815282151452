.AccordionItem {
    background-color: #f0f4f5;
    border-radius: 3px;
    padding: 20px 32px;
    margin-top: 5px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 62px;
    height: max-content;
}

.AccordionTitle {
    color: #000;
    font-family: PoppinsMedium;
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    line-height: 30px;
    width: 100%;
    justify-content: space-between;
}

.AccordionTitle span {
    max-width: 380px;
}

.AccordionContent {
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    animation-name: accordion-appear;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    margin-top: 30px;
    font-family: PoppinsLight;
    margin-bottom: 20px;
}

@keyframes accordion-appear {
    0% {
      max-height: 0;
      opacity: 0;
    }
    100% {
      max-height: 1000px;
      opacity: 1;
    }
}

.AccordionItem .AccordionIcon {
    transition: transform 0.3s ease-out;
}
  
.active .AccordionIcon {
    animation-name: plus-to-minus;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.AccordionIcon .rotate-minus {
    animation-name: minus-to-plus;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes plus-to-minus {
    0% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes minus-to-plus {
    0% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
  