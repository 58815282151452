.RequestForm {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    max-width: 331px;
    background: white;
    box-shadow: 0 0 40px rgba(16,57,95,.08);
    padding: 30px;
    height: max-content;
}

.FormUnderTitle {
    color: #000;
    font-family: PoppinsMedium;
}

.FormTitle {
    color: #000;
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: PoppinsBold;
}

.FormSubtitle {
    color: #000;
    font-family: PoppinsLight;
    margin-bottom: 40px;
}

.FormInput {
    max-width: 350px;
    height: 54px;
    background-color: #f0f4f5;
    border: 1px #000;
    border-radius: 3px;
    flex: 0 auto;
    font-family: PoppinsMedium;
    margin-bottom: 8px;
    padding-left: 20px;
    transition: background-color .25s;
}

textarea {
    padding-top: 20px;
}

#RequestFormBtn {
    width: 100%;
    padding: 10px 40px;
    z-index: 99;
    font-size: 14pt;
    margin-top: 20px;
    font-family: PoppinsMedium;
    color: #fff;
    background: #000;
}

.XIcon {
    display: flex;
    width: 100%;
    justify-content: end;
}

.XIcon img {
    width: 20px;
    cursor: pointer;
}