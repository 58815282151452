.Circle {
    min-width: 5vmin;
    height: 5vmin;
    border-radius: 50%;
    border: 2px solid black;
    background: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CircleNumber {
    color: #000;
    font-size: 20px;
    font-family: PoppinsBold;
}