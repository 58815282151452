/* Banner */

.Banner {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    margin: auto;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ .Banner video { width: 200vw; height: 100%; } }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ .Banner video { width: 200vw; height: 100%; } }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */  .Banner video { width: 200vw; height: 100%; }}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ .Banner video { width: 150vw; height: 100vh; } }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ .Banner video { width: 130vw; height: 100vh; } }
@media (min-width:1281px) { /* hi-res laptops and desktops */ .Banner video { width: 100%; height: 100%; }  }
.Banner video {
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -100;
    filter: brightness(50%);
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}

.BannerTitle {
    position: absolute;
    top: 30vmin;
    left: 5%;
    z-index: 99;
    font-size: 4vmin;
    font-family: PoppinsBold;
    color: #fff;
}

.BannerSubtitle {
    position: absolute;
    top: 50vmin;
    left: 5%;
    width: 80vmin;
    z-index: 99;
    font-size: 2.5vmin;
    font-family: PoppinsMedium;
    color: #fff;
}

.BannerWidget {
    position: absolute;
    top: 77vmin;
    left: 5%;
    z-index: 99;
}

#BannerBtn {
    position: absolute;
    top: 85vmin;
    left: 5%;
    width: max-content;
    padding: 10px 40px;
    z-index: 99;
    font-size: 14pt;
    font-family: PoppinsMedium;
    color: #fff;
    border: #000 1px solid;
    background: #000;
}


/* About */

.AboutOurView {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    margin: auto;
}

.ViewTitle {
    width: 60%;
    text-align: center;
    font-size: 8vmin;
    margin-top: 5%;
    color: #000;
    font-family: PoppinsMedium;
}

.OurView {
    width: 65%;
    text-align: center;
    font-size: 14pt;
    margin-top: 4vmin;
    color: #6e707b;
    font-family: PoppinsLight;
}

.IndustriesList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: 6vmin;
    padding-bottom: 10vmin;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ .IndustryItem { width: 180px; height: 150px; } }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ .IndustryItem { width: 180px; height: 150px; } }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ .IndustryItem { width: 200px; height: 150px; }}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ .IndustryItem { width: 200px; height: 150px; } }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ .IndustryItem { width: 250px; height: 200px; } }
@media (min-width:1281px) { /* hi-res laptops and desktops */ .IndustryItem { width: 250px; height: 200px; }  }
.IndustryItem {
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: start;
    align-items: end;
    margin: 20px;
    border-radius: 5px;
}

.IndustryName {
    background: #fff;
    color: #000;
    height: max-content;
    width: max-content;
    border-radius: 50px;
    padding: 5px 15px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 10pt;
    font-family: PoppinsMedium;
}

/* Questions */

.Questions {
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 110vh;
    width: 65%;
    margin: auto;
}

.QuestionsTitle {
    width: 70%;
    text-align: start;
    font-size: 48px;
    margin-top: 20vmin;
    color: #000;
    font-family: PoppinsMedium;
}

.QuestionsBlocks {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20vmin;
}

.BlockColumn {
    margin-top: 10vmin;
    display: flex;
    height: max-content;
    flex-direction: column;
}

.BlockTitle {
    text-align: start;
    font-size: 28px;
    color: #000;
    margin-bottom: 5vmin;
    font-family: PoppinsMedium;
}


/* Request popup */

.RequestModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.54);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20%;
    z-index: 9999;
}